import { downloadFiles } from "../../api/s3Service"

export const generateBackGroundColor = (cell) => {
    if (!cell) return
    if (cell === "1-1" || cell === "charta1" || cell === "lozenets1" || cell === "other1" || cell === "collect1" || cell === "South Park1" || cell ==="Office Room" || cell ==="1-Boryana Petkova") {
        return "#EAC435"
    } else if (cell === "1-2" || cell === "charta2" || cell === "lozenets2" || cell === "other2" || cell === "collect2" || cell === "South Park2" || cell === "Old Artists Room" || cell ==="2") {
        return "#345995"
    } else if (cell === "1-3" || cell === "charta3" || cell === "lozenets3" || cell === "other3" || cell === "collect3" || cell === "South Park3" || cell === "Modern Art" || cell ==="3-Boris Kolev") {
        return "#E40066"
    } else if (cell === "1-4" || cell === "charta4" || cell === "lozenets4" || cell === "other4" || cell === "collect4" || cell === "South Park4" || cell === "Carpet Room" || cell ==="4-Georgi Ruzhev") {
        return "#03CEA4"
    } else if (cell === "2-1" || cell === "other5" || cell === "Books Room" || cell ==="5") {
        return "#FB4D3D"
    } else if (cell === "2-2" || cell === "other6" || cell === "Black Room" || cell ==="6") {
        return "#84DCC6"
    } else if (cell === "2-3" || cell === "Foyer" || cell ==="7") {
        return "#A5FFD6"
    } else if (cell === "2-4" || cell ==="8") {
        return "#C98CA7"
    } else if (cell === "3-1" || cell ==="9") {
        return "#E8FCC2"
    } else if (cell === "3-2" || cell ==="10") {
        return "#D63230"
    } else if (cell === "3-3") {
        return "#CFD186"
    } else if (cell === "3-4") {
        return "#8B5FBF"
    }  
}

export const prepareImagesForLocationChange = (handleDialogType) => {
    handleDialogType('location')
}

export const checkBoxHandler = (selectedItems, setSelectedItems, items, id) => {
    if (selectedItems.some(selectedItem => selectedItem.id === id)) {
        setSelectedItems(selectedItems.filter(image => image.id !== id));
    } else {
        setSelectedItems([...selectedItems, items.find(image => image.id === id)]);
    }
}

export const handleEdit = (arts, navigate) => {
    localStorage.setItem('currentImages', JSON.stringify(arts));
    navigate('/edit-page')
};

export const downloadOriginalImages = async (downloadKeys) => {
    const response = await downloadFiles(downloadKeys)
    if (response.data.result.lenght === 1) {
        window.open(response.data.result[0])
    } else {
        for (let link of response.data.result) {
            window.open(link)
        }
    }
}