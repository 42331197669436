import { useState } from 'react';

const useNotification = () => {
    const [success, setSuccess] = useState({ state: false, message: '' });
    const [error, setError] = useState({ state: false, message: '' });

    const showSuccess = (message) => {
        setSuccess({ state: true, message });
    };

    const showError = (message) => {
        setError({ state: true, message });
    };

    const clearNotifications = () => {
        setSuccess({ state: false, message: '' });
        setError({ state: false, message: '' });
    };

    return { success, error, showSuccess, showError, clearNotifications };
};

export default useNotification;
