import React, { useState, useEffect, useContext } from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';
import { getAllStorages, getAvailablePositions } from '../../api/storageService';
import { EntriesContext } from '../contexts/EntriesContext';

function CascadingDropdowns({
    formControlData,
    onDropdownChange,
    isOpenInModal = null
}) {
    const {currentImages} = useContext(EntriesContext);
    const [location, setLocation] = useState();
    const [cells, setCells] = useState([]);
    const [availablePositions, setAvailablePositions] = useState([]);
    const [storages, setStorages] = useState([]);

    useEffect(() => {
        getStorages();
    }, []);

    const getStorages = async () => {
        try {
            const response = await getAllStorages();
            setStorages(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const findAvailablePositions = async (selectedCell, selectedLocation) => {
        try {
            const response = await getAvailablePositions(selectedCell, selectedLocation);
            const freePositions = response.data;
            setAvailablePositions(freePositions);
        } catch (error) {
            console.error(error);
        }
    };

    const changeLocation = (newValue) => {
        setLocation(newValue);
        const selectedStorage = storages.find((storage) => storage.name === newValue);
        const selectedCells = selectedStorage ? selectedStorage.cells.map((cell) => cell.name) : [];
        setCells(selectedCells);

        onDropdownChange({
            storageLocation: newValue,
            cell: "",
            position: "",
        });
    };

    const changeCell = (newValue) => {
        findAvailablePositions(newValue, location);
        onDropdownChange({
            cell: newValue
        });
    };

    const changePosition = (newValue) => {
        onDropdownChange({
            position: newValue
        });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Autocomplete
                disablePortal
                sx={{ width: isOpenInModal === 'location' ? '70%' : '50vw', marginBottom: '1rem' }}
                options={storages.map((storage) => storage.name)}
                value={formControlData?.storageLocation || null}
                renderInput={(params) => <TextField {...params} label="Location *" />}
                onChange={(event, newValue) => changeLocation(newValue)}
                isOptionEqualToValue={(option, value) => option === value}
            />

            <Autocomplete
                disablePortal
                sx={{ 
                    width: isOpenInModal === 'location' ? '70%' : '50vw',
                    marginBottom: '1rem'
                }}
                disabled={!location || location === 'Sold'}
                options={cells}
                value={formControlData?.cell || null}
                renderInput={(params) => <TextField {...params} label="Cell" />}
                onChange={(event, newValue) => changeCell(newValue)}
                isOptionEqualToValue={(option, value) => option === value}
            />

            <Autocomplete
                disablePortal
                sx={{ width: isOpenInModal === 'location' ? '70%' : '50vw', marginBottom: '1rem' }}
                disabled={!location || location === 'Sold' || (isOpenInModal === 'location' && currentImages.length > 1)}
                options={availablePositions}
                value={formControlData?.position || null}
                renderInput={(params) => <TextField {...params} label="Position" />}
                onChange={(event, newValue) => changePosition(newValue)}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option.toString()}
            />
        </Box>
    );
}

export default CascadingDropdowns;
