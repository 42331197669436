import React, { useContext, useState } from "react";
import Message from "../reusable/Message";
import "react-lazy-load-image-component/src/effects/blur.css";
import { EntriesContext } from "../contexts/EntriesContext";
import SearchAndFiltersBar from "../filters/SearchAndFiltersBar";
import ThumbnailView from "./ThumbnailView";
import DetailsView from "./DetailsView";
import ListView from "./ListView";
import SelectAllIcon from '../../assets/select-all.svg'
import UnselectAllIcon from '../../assets/unselect-all.svg'
import DeleteDialog from "../reusable/DeleteDialog";
import LocationChangeDialog from "../LocationChangeDialog";
import PaginationComponent from "../PaginationComponent";
import { useMediaQuery } from "@mui/material";
import MobileListView from "./MobileListView";
import './GalleryContent.css'
import Actions from '../reusable/Actions'
import useNotification from "../hooks/useNotification";

const NewGalleryContent = () => {

    const {
        page,
        setPage,
        currentImages,
        setCurrentImages
    } = useContext(EntriesContext);

    const { error, clearNotifications } = useNotification();

    const [isLoading, setIsLoading] = useState(false);
    const [locationChanged, setLocationChanged] = useState(false)
    const [searchResults, setSearchResults] = useState([]);
    const [dialogType, setDialogType] = useState(null);
    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
    const [viewMode, setViewMode] = useState('details')
    const [paginationDisabled, setPaginationDisabled] = useState(false);

    const handleDialogType = (type) => {
        setDialogType(type)
    }

    const renderViewMode = () => {
        switch(viewMode) {
        case 'thumbnail':
            return  <ThumbnailView
                searchResults={searchResults}
            />
        case 'details':
            return <DetailsView
                handleDialogType={handleDialogType}
                searchResults={searchResults}
            />
        default: 
            return !isSmallDevice ?
                <ListView
                    searchResults={searchResults}
                    handleDialogType={handleDialogType}
                /> :
                <MobileListView 
                    searchResults={searchResults}
                    handleDialogType={handleDialogType}
                />
        }
    }

    const handleLocationChange = () => {
        setLocationChanged(prev => !prev)
    }

    const handlePage = (newPage) => {
        setPage(newPage)
    }

    const handleSelectAll = () => {
        if (currentImages.length === searchResults.length) {
            setCurrentImages([]);
        } else {
            setCurrentImages([
                ...currentImages, 
                ...searchResults.filter(image => 
                    !currentImages.some(
                        currentImage => currentImage.id === image.id
                    ))
            ]);
        }
    }
    
    return (
        <>
            <Message
                open={error.state}
                handleClose={clearNotifications}
                message={error.message}
                severity="error" />

            <LocationChangeDialog 
                dialogType={dialogType}
                handleDialogType={handleDialogType}
                handleLocationChange={handleLocationChange}
            />
            <DeleteDialog
                dialogType={dialogType}
                handleDialogType={handleDialogType}
                isLoading={isLoading}
                handleIsLoading={setIsLoading}
                    
            />
            {searchResults.length || searchResults.length && viewMode === 'details' ?
                <div style={{top: isSmallDevice ? '72px' : '88px'}} className="select-unselect-all-icon-container">
                    <img 
                        style={isSmallDevice ?{width: '24px'} : {}}
                        onClick={handleSelectAll} 
                        alt="select unselect all icon" 
                        src={currentImages.length === searchResults.length ? UnselectAllIcon : SelectAllIcon} 
                    />
                </div>   
                :
                null
            }
            {currentImages.length > 1 || (viewMode === 'thumbnail' && currentImages.length) ?
                <Actions 
                    classes="action-buttons-container"
                    style={{top: isSmallDevice ? '100px' : '130px'}}
                    fontSize={isSmallDevice ? 'medium' : 'large'}
                    arts={currentImages}
                    handleDialogType={handleDialogType}
                />
                :
                null
            }
            <div className="gallery-content-container">
                <SearchAndFiltersBar
                    setPaginationDisabled={setPaginationDisabled}
                    handleSearchResults={setSearchResults}
                    isDeleting={isLoading}
                    locationChanged={locationChanged}
                    page={page}
                    viewMode={viewMode}
                    handleViewMode={setViewMode}
                />
            </div>
            {!searchResults.length && <div className="no-data-container">Nothing was found!</div>}
            {renderViewMode()}
            {searchResults.length && !paginationDisabled ?
                <PaginationComponent 
                    page={page}
                    handlePage={handlePage}
                /> :
                null
            }
        </>
    );
};
export default NewGalleryContent;