import axios from "axios";
import { API_URL } from "./constants";

export const getPaginatedArtworks = async (page, count, sortField, sortOrder, name) => {
    if (!page && !count && !sortField && !sortOrder) {
        return await axios.get(`${API_URL}/artworks/filterByStorage/${name}`);
    } else {
        return await axios.get(
            `${API_URL}/artworks/filterByStorage/${name.split(':')[1]}?count=${count}&page=${page}&sortField=${sortField}&sortOrder=${sortOrder}`
        );
    }
}

export const filterArtworks = async (keywords, selectedArtist, selectedCell, sortField, sortOrder) => {
    return await axios.get(`${API_URL}/artworks/filter?sortField=${sortField}&sortOrder=${sortOrder}`, {
        params: {
            keywords: keywords,
            selectedArtist: selectedArtist,
            selectedCell: selectedCell
        }
    })
}

export const updateArtwork = async (updatedEntry, id) => {
    return await axios.put(`${API_URL}/artworks/updateOne/${id}`, updatedEntry);
}

export const deleteArtwork = async (params) => {
    return await axios.delete(`${API_URL}/artworks/deleteOne/${params}`, { params });
}
