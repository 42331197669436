import React, { useContext, useState } from 'react';
import CustomDialog from './reusable/CustomDialog';
import CascadingDropdowns from './reusable/CascadingDropdowns';
import { EntriesContext } from './contexts/EntriesContext';
import { updateLocations } from '../api/storageService';
import './LocationChangeDialog.css';

const LocationChangeDialog = ({
    dialogType,
    handleDialogType,
    handleLocationChange
}) => {
    const {
        currentImages,
        setCurrentImages,
        setIsEditMode
    } = useContext(EntriesContext);

    const [formControlData, setFormControlData] = useState({
        storageLocation: "",
        cell: "",
        position: "",
    });

    const updateLocation = async () => {
        const ids = currentImages.map(image => image.id);
        try {
            await updateLocations(ids, formControlData);
            setIsEditMode(false);
            setCurrentImages([]);
        } catch (error) {
            console.log(error);
        } finally {
            handleDialogType(null);
            setCurrentImages([]);
            handleLocationChange();
        }
    };

    const handleDropdownChange = (updatedData) => {
        setFormControlData(prevData => ({
            ...prevData,
            ...updatedData
        }));
    };

    return (
        <>
            {dialogType === 'location' && (
                <CustomDialog
                    openModal={dialogType === 'location'}
                    setOpenModal={() => handleDialogType(null)}
                    title="This will change the location of all selected entries, are you sure?"
                    handleClickYes={updateLocation}
                    handleClickNo={() => {setFormControlData({
                        storageLocation: "",
                        cell: "",
                        position: ""});
                    handleDialogType(null)}}
                    confirmButtonText="Yes"
                    cancelButtonText="No"
                >
                    Summary:
                    <div className="current-locations-info-container">
                        {currentImages.map((image) => (
                            <div className="current-image-info" key={image.id}>
                                <p>{image.artist}/{image.title}/{image.dimensions}</p>
                                <p>{image.storage.name}/{image.cell_t ? image.cell_t.name : 0}/{image.position_t ? image.position_t.name : 0}</p>
                            </div>
                        ))}
                    </div>
                 
                    <CascadingDropdowns
                        formControlData={formControlData}
                        onDropdownChange={handleDropdownChange}
                        isOpenInModal={dialogType}
                    />
                </CustomDialog>
            )}
        </>
    );
};

export default LocationChangeDialog;
